export const COINS = {
  'Bitcoin': 'bitcoin',
  'Ethereum': 'ethereum',
  'BNB': 'binancecoin',
  'Solana': 'solana',
  'XRP': 'ripple',
  'Cardano': 'cardano',
  'Avalanche': 'avalanche-2',
  'Dogecoin': 'dogecoin',
  'Tron': 'tron',
  'Chainlink': 'chainlink',
  'Polkadot': 'polkadot',
  'Bitcoin Cash': 'bitcoin-cash',
  'Litecoin': 'litecoin',
  'Near': 'near',
  'ICP': 'internet-computer',
  'Shiba Inu': 'shiba-inu',
  'LEO Token': 'leo-token',
  'Toncoin': 'the-open-network',
  'Ethereum Classic': 'ethereum-classic',
  'Aptos': 'aptos',
  'Sui': 'sui',
  'Stellar': 'stellar',
  'Hedera': 'hedera-hashgraph',
  'Aave': 'aave',
  'Injective': 'injective-protocol',
  'Arbitrum': 'arbitrum',
  'Optimism': 'optimism',
  'Pepe': 'pepe',
  'Sei': 'sei-network',
  'Uniswap': 'uniswap',
  'Jupiter': 'jupiter-exchange-solana',
  'dYdX': 'dydx-chain',
  'GMX': 'gmx',
  'Drift': 'drift-protocol',
  'Hyperliquid': 'hyperliquid'
};

export const COIN_NAMES = Object.keys(COINS);
export const COIN_IDS = Object.values(COINS);

export const TOTAL_POINTS = 57900000;
export const TOTAL_HYPE = 1000000000;
export const POINTS_ALLOCATION = 0.31; // 31%
export const ADDITIONAL_CIRCULATING = 0.00012; // 0.012%
